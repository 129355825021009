import request from '@/utils/request.js';
var url = "/manage/waiter/access/record";

/**
 * 分页查询
 * @param params
 */
export function findPage(params) {
    return request({
        url: `${url}/findPage`, method: 'get', params: params
    })
}

/**
 * 保存数据(新增/修改)
 * @param params
 */
export function saveData(params) {
    return request.post(`${url}/saveData`, JSON.stringify(params))
}