<template>
    <div>
        <el-dialog title="回访" :visible.sync="detail.visible" width="600px" @close="handleClose" append-to-body>
            <el-row style="text-align: right;margin-bottom: 20px">
                <el-button type="success" size="medium" @click="submitForm">保存</el-button>
                <el-button type="info" size="medium" @click="handleClose">取消</el-button>
            </el-row>
            <el-form :model="dataFrom" :rules="dataFromRules" ref="dataFrom" style="width: 500px" label-width="100px"
                     size="medium">
                <el-form-item label="回访日期" prop="times">
                    <el-date-picker
                            style="width: 100%"
                            v-model="dataFrom.accessTime"
                            type="date"
                            value-format="yyyy-MM-dd"
                            placeholder="选择日期">
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="回访类型" prop="type">
                    <el-select v-model="dataFrom.type" style="width: 100%">
                        <el-option v-for="item in accessTypeList" :key="item.id" :value="item.dictValue"
                                   :label="item.dictLabel"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="回访内容" prop="remarks">
                    <el-input v-model="dataFrom.remarks" type="textarea" :rows="4"></el-input>
                </el-form-item>
            </el-form>
        </el-dialog>
    </div>
</template>

<script>
import * as recordApi from "@/api/waiter/waiterAccessRecord"
import * as dictionaryApi from "@/api/system/dictionary"

export default {
    name: "WaiterAccessDetail",
    props: {
        detail: {
            type: Object,
            default: {
                visible: false,
            }
        },
        studentId: {
            type: Number
        },
        servicesId: {
            type: Number
        },
        callback:{
            type:Function,
            default: function () {

            }
        }
    },
    data() {
        return {
            accessTypeList:[],
            dataFrom: {},
            dataFromRules: {
                accessTime: [{required: true, message: '请输入回访日期', trigger: 'blur'}],
                type: [{required: true, message: '请输入回访方式', trigger: 'blur'}],
                remarks: [{required: true, message: '请输入回访内容', trigger: 'blur'}],
            }
        }
    },
    mounted() {
        this.dataFrom.studentId = this.studentId
        this.dataFrom.servicesId = this.servicesId
        this.findAccessType()
    },
    methods: {
        findAccessType() {
            dictionaryApi.findChildrenByCode('accessType').then(res => {
                if (res.success) {
                    this.accessTypeList = res.data
                }
            })
        },
        submitForm() {
            recordApi.saveData(this.dataFrom).then(res => {
                if (res.success) {
                    this.$message.success(res.msg)
                    this.handleClose()
                    this.callback()
                } else {
                    this.$message.error(res.msg)
                }
            })
        },
        handleClose() {
            this.detail.visible = false
        }
    }
}
</script>

<style scoped>

</style>